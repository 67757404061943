body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  color: #777;
  /* width: 100% !important; */
  height: 100% !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main{
  overflow: hidden;
}

h2, h3, h4 {
  font-family: "Raleway", sans-serif;
}

h2 {
  text-transform: uppercase;
  margin: 0 0 20px;
  color: #333;
  font-size: 36px;
  font-weight: 700;
}

h3 {
  font-size: 20px;
  font-weight: 600;
  color: #333;
}

h4 {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

h5 {
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
}

p {
  font-size: 15px;
}

a.nav-link {
  font-weight: 400;
  color: #608dfd;
}

a.nav-link:hover, a.nav-link:focus {
  text-decoration: none;
  color: #608dfd;
}

a.page-scroll.navbar-brand {
  font-size: 24px;
}

ul,
ol {
  list-style: none;
}

ul, ol {
  padding: 0;
  webkit-padding: 0;
  moz-padding: 0;
}

hr {
  height: 2px;
  width: 70px;
  text-align: center;
  position: relative;
  background: #1e7a46;
  margin-bottom: 20px;
  border: 0;
}