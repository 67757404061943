#menu {
    padding: 15px;
    transition: all 0.8s;
}

#menu a.navbar-brand {
    font-family: 'Raleway' serif;
    font-weight: 700;
}

#menu.navbar-default {
    border-color: rgba(231, 231, 231, 0);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

#menu.navbar-default .navbar-nav > li > a {
    font-size: 15px;
    color: #131212;
    text-transform: uppercase;
    border-radius: 0;
    padding: 8px 2px;
    margin: 9px 20px 0;
    font-weight: 400;
}

#menu.navbar-default .navbar-nav > li > a:after {
    position: absolute;
    display: block;
    left: 0;
    bottom: -1px;
    height: 2px;
    width: 0;
    content: "";
    transition: width 0.2s;
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
}

#menu.navbar-default .navbar-nav > li > a:hover:after {
    width: 100%;
}

.navbar-nav > .active > a,
.navbar-nav > .active > a:hover,
.navbar-nav > .active > a:focus {
    background-color: transparent;
}

.navbar-nav > .active > a:after,
.navbar-nav > .active > a:hover:after,
.navbar-nav > .active > a:focus:after {
    display: block !important;
    position: relative !important;
    bottom: -1px !important;
    width: 100% !important;
    height: 2px !important;
    left: 0 !important;
    content: "" !important;
    transform: width 0.2s !important;
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%) !important;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background-color: #fff;
    border-color: #608dfd;
}

.navbar-toggle {
    border-radius: 0; 
}

.navbar-default .navbar-toggle:hover > .icon-bar {
    background-color: #608dfd;
}