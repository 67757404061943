a.btn.btn-custom.btn-lg {
    position: absolute;
    text-transform: uppercase;
    background-color: #5ca9fb;
    border: 0;
    transition: all 0.5s linear;
    background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
    border-radius: 25px;
    color: #fff;
}

@media (max-width: 576px) {
    /* Styles for mobile phones */
    a.btn.btn-custom.btn-lg {
        margin-top: -45%;
        margin-left: 20%;
        letter-spacing: 1px;
        padding: 14px 34px;
        font-size: 15px;
        font-weight: 500;
    }
  }

  @media (min-width: 577px) and (max-width: 768px) {
    /* Styles for tablets */
    a.btn.btn-custom.btn-lg {
        margin-top: 5%;
        margin-left: 35%;
        letter-spacing: 1px;
        padding: 14px 34px;
        font-size: 15px;
        font-weight: 500;
    }
  }

  @media (min-width: 769px) and (max-width: 992px) {
    /* Styles for small laptops */
    a.btn.btn-custom.btn-lg {
        letter-spacing: 1px;
        padding: 14px 34px;
        font-size: 15px;
        font-weight: 500;
    }
  }
  
  /* Desktop styles */
  @media (min-width: 993px) and (max-width: 1200px) {
    /* Styles for desktops */
    a.btn.btn-custom.btn-lg {
        margin-left: 34%;
        letter-spacing: 1px;
        padding: 14px 34px;
        font-size: 15px;
        font-weight: 500;
        margin-top: 4rem;
    }
  }

  @media (min-width: 1201px) {
    /* Styles for large laptops and desktops */
    a.btn.btn-custom.btn-lg {
        margin-top: 5%;
        margin-left: 39%;
        letter-spacing: 1px;
        padding: 14px 34px;
        font-size: 15px;
        font-weight: 500;
    }
  }

a.btn.btn-custom:hover,
a.btn.btn-custom:focus,
a.btn.btn-custom:focus,
a.btn.btn-custom:active,
a.btn.btn-custom:active {
    color: #fff;
    background-image: none;
    background-color: #6372ff;
}

.btn:active,
.btn.active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

a:focus,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  outline-offset: none;
}